import {
  Button,
  Carousel,
  Progress,
  Spinner,
  Typography,
} from '@material-tailwind/react';
import { HiChevronLeft, HiOutlinePlusCircle, HiTrash } from 'react-icons/hi';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import { useNavigate, useParams } from 'react-router-dom';
import './leaflet.css';
import {
  useFindProjeto,
  useProjetoSituacao,
} from '../../hooks/query/useProjetoQuery';
import { useFindLocalizacaoByProjetoId } from '../../hooks/query/useLocalizacaoQuery';
import { format, parseISO } from 'date-fns';
import { DialogFormProjeto } from '../../components/DialogFormProjeto';
import { useEffect, useRef, useState } from 'react';
import {
  useFindPlanoAcaoByProjetoId,
  useFindPlanoAcaoBySituacaoId,
} from '../../hooks/query/usePlanoAcaoQuery';
import { TablePlanoAcao } from '../../components/TablePlanoAcao';
import { DialogFormPlanoAcao } from '../../components/DialogFormPlanoAcao';
import { EmptyState } from '../../components/EmptyState';
import { BoxError } from '../../components/BoxError';
import { setValueMasks } from '../../utils/number.utils';
import { DialogFormEditProjeto } from '../../components/DialogFormEditProjeto';
import { DialogLocalizacao } from '../../components/Projeto/DialogLocalizacao';
import { usePDF } from 'react-to-pdf';
import { useQueryClient } from 'react-query';
import { PDFDownloadLink, Document, Page } from '@react-pdf/renderer';
import { ReportDetailProjeto } from '../../components/Report/ReportDetailProjeto';
import { toPng } from 'html-to-image';
import { GridPlanoAcao } from '../../components/GridPlanoAcao';
import { GridPlanoAcaoMobile } from '../../components/GridPlanoAcao/index-mobile';
import { BoxProjetoSituacao } from '../../components/BoxProjetoSituacao';
import { DialogAssociarSituacoes } from '../../components/DialogAssociarSituacoes';
import { DialogRemoveItem } from '../../components/DialogRemoveItem';
import { toast } from 'react-toastify';
import { deleteSituacaoProjeto } from '../../providers/situacao';
import { CardArquivo } from '../../components/CardArquivo';
import { deleteProjeto, TBodyProjetoArquivo } from '../../providers/projeto';
import { uploadArquivo } from '../../providers/arquivo';
import { useAllArquivo } from '../../hooks/query/useArquivoQuery';

export function ProjetoDetail() {
  const { id } = useParams<any>();
  const navigate = useNavigate();
  const { toPDF, targetRef } = usePDF({ filename: 'projeto.pdf' });

  const [binaryData, setBinaryData] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [openDialogRemove, setOpenDialogRemove] = useState(false);
  const [loadingRemove, setLoadingRemove] = useState(false);
  const [dialogForm, setDialogForm] = useState(false);
  const [dialogAssociarSituacao, setDialogAssociarSituacao] = useState(false);
  const [dialogFormLoc, setDialogFormLoc] = useState(false);
  const [dialogAcaoForm, setDialogAcaoForm] = useState(false);
  const [activeTab, setActiveTab] = useState('detalhes');
  const [hiddenPdf, setHiddenPdf] = useState('hidden');

  const queryClient = useQueryClient();

  const projetoQuery = useFindProjeto(Number(id));
  const projeto = projetoQuery.data;

  const localizacaoQuery = useFindLocalizacaoByProjetoId(Number(id));
  const localizacao = localizacaoQuery.data || [];

  const projetoSituacaoQuery = useProjetoSituacao(Number(id));
  const projetoSituacao = projetoSituacaoQuery.data || [];

  const planoAcaoQuery = useFindPlanoAcaoByProjetoId(Number(id));
  const planoAcao = planoAcaoQuery.data || [];

  const elementRef = useRef<any>(null);

  const allArquivos = useAllArquivo();
  const arquivosList = allArquivos.data || [];
  let arquivos: TBodyProjetoArquivo[] = [];

  if (arquivosList.length > 0) {
    arquivos = arquivosList.map( (k) => {
      return {
        id: k?.id,
        nome: k?.descricao,
        tamanho: k?.tamanho,
        data: k?.dataCriacao,
        icone: k?.tipo,
        identificador: k?.identificador
      };
    });
  }

  const htmlToImageConvert = () => {
    toPng(elementRef.current, { cacheBust: false })
      .then((dataUrl) => {
        const link = document.createElement('a');
        link.download = 'my-image-name.png';
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // useEffect(() => {
  //   if (localizacao.length > 0 && localizacao[0].latitude && localizacao[0].longitude && elementRef && elementRef.current) {
  //     toJpeg(elementRef.current, { cacheBust: false })
  //       .then((dataUrl) => {
  //         console.log(dataUrl)
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   }
  // }, [localizacao, elementRef])

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  async function upload(base64String: any, meta: string) {
    try {
      if (base64String) {
        setLoadingRemove(true);
        await uploadArquivo({ conteudo: base64String, descricao: meta });
        toast('Arquivo submetido com sucesso', { type: 'success' });
        queryClient.resetQueries(['all-arquivo']);
        queryClient.invalidateQueries(['all-arquivo']);
        setLoadingRemove(false);
      }
    } catch (err) {
      setLoadingRemove(false);
      toast('Erro ao realizar o upload do arquivo', { type: 'error' });
    }
  }

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      await upload(file, file?.name);
    }
  };

  function handleToPdf() {}

  function removerImagemCarrossel() {
    console.log('Carrossel');
  }

  function formatDate(date: string) {
    try {
      const splitDate = date.split('T');
      const splitDate2 = splitDate[0].split('-');
      return `${splitDate2[2]}/${splitDate2[1]}/${splitDate2[0]}`;
    } catch (err) {
      return '-';
    }
  }

  function handleOpenDialogRemove() {
    setOpenDialogRemove((prev) => !prev);
  }

  function handleDialogFormProject() {
    setDialogForm((prev) => !prev);
  }

  function handleDialogFormLoc() {
    setDialogFormLoc((prev) => !prev);
  }

  function handleDialogAcaoForm() {
    setDialogAcaoForm((prev) => !prev);
  }

  function handleDialogAssociarSituacao() {
    setDialogAssociarSituacao((prev) => !prev);
  }

  const [imageMap, setImageMap] = useState('');

  async function handleRemove() {
    try {
      setLoadingRemove(true);
      await deleteProjeto(Number(id));
      toast('Projeto excluído com sucesso', { type: 'success' });
      setLoadingRemove(false);
      handleOpenDialogRemove();
      navigate('/internal/projeto');
    } catch (err) {
      setLoadingRemove(false);
      toast('Erro ao excluir cenário', { type: 'error' });
    }
  }

  function mapReady() {
    setTimeout(() => {
      toPng(elementRef.current, { cacheBust: false })
        .then((dataUrl) => {
          setImageMap(dataUrl);
        })
        .catch((err) => {
          console.log(err);
        });
    }, 500);
  }

  return (
    <div>
      <div
        className="flex flex-row items-center mb-[24px] cursor-pointer"
        onClick={() => navigate('/internal/projeto')}
      >
        <HiChevronLeft size={22} color="#3A57F2" />
        <h1 className="font-normal text-base text-[#3A57F2]">Voltar</h1>
      </div>

      {!projetoQuery.isLoading && projetoQuery.isSuccess && (
        <>
          <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between mb-[24px]">
            <div>
              <h1 className="text-[24px] lg:text-[42px] text-dark-base">
                {projeto?.nome}
              </h1>
            </div>
            <div className="lg:flex lg:flex-row mt-[16px] lg:mt-0">
              <div className="lg:mr-[16px] mb-[8px] lg:mb-0">
                <PDFDownloadLink
                  document={
                    <ReportDetailProjeto
                      projeto={projeto}
                      localizacao={localizacao}
                      acoes={planoAcao}
                      imageMap={imageMap}
                    />
                  }
                  fileName="projeto.pdf"
                >
                  {({ blob, url, loading, error }) => (
                    <Button
                      className="normal-case text-sm font-medium text-white bg-blue-button border-blue-button w-full"
                      variant="outlined"
                    >
                      Gerar relatório
                    </Button>
                  )}
                </PDFDownloadLink>
              </div>
            </div>
          </div>
          <div className="w-full">
            <Carousel className="rounded-xl h-[400px]" loop>
              <img
                src="https://images.unsplash.com/photo-1497436072909-60f360e1d4b1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2560&q=80"
                alt="image 1"
                className="h-full w-full object-cover"
              />
              <img
                src="https://images.unsplash.com/photo-1493246507139-91e8fad9978e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2940&q=80"
                alt="image 2"
                className="h-full w-full object-cover"
              />
              <img
                src="https://images.unsplash.com/photo-1518623489648-a173ef7824f3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2762&q=80"
                alt="image 3"
                className="h-full w-full object-cover"
              />
            </Carousel>
            <div className="relative bottom-14 z-50 flex items-center justify-end mr-5">
              <div
                onClick={() => removerImagemCarrossel()}
                className="bg-[#fff] p-2 rounded-3xl cursor-pointer "
              >
                <HiTrash color="#0062FF" size={16} />
              </div>
            </div>
            <div className="w-full flex items-center justify-end">
              <div className="cursor-pointer mr-2">
                <HiOutlinePlusCircle color="#0062FF" size={22} />
              </div>
            </div>
          </div>

          <div className="mt-[24px] w-full border-b border-b-[#E0E2E7] flex flex-row items-center">
            <div
              onClick={() => setActiveTab('detalhes')}
              className={`pb-[10px] w-fit	cursor-pointer ${
                activeTab === 'detalhes' && `border-b-[2px] border-b-[#0062FF]`
              }`}
            >
              <Typography
                className={`${
                  activeTab === 'detalhes'
                    ? `text-[#0062FF] font-semibold`
                    : 'text-[#667085] font-normal'
                }`}
              >
                Detalhes
              </Typography>
            </div>
            <div className="w-[16px]" />
            <div
              onClick={() => setActiveTab('ações')}
              className={`pb-[10px] w-fit	cursor-pointer ${
                activeTab === 'ações' && `border-b-[2px] border-b-[#0062FF]`
              }`}
            >
              <Typography
                className={`${
                  activeTab === 'ações'
                    ? `text-[#0062FF] font-semibold`
                    : 'text-[#667085] font-normal'
                }`}
              >
                Ações
              </Typography>
            </div>
            <div className="w-[16px]" />
            <div
              onClick={() => setActiveTab('situacoes')}
              className={`pb-[10px] w-fit	cursor-pointer ${
                activeTab === 'situacoes' && `border-b-[2px] border-b-[#0062FF]`
              }`}
            >
              <Typography
                className={`${
                  activeTab === 'situacoes'
                    ? `text-[#0062FF] font-semibold`
                    : 'text-[#667085] font-normal'
                }`}
              >
                Situações
              </Typography>
            </div>
            <div className="w-[16px]" />
            <div
              onClick={() => setActiveTab('arquivos')}
              className={`pb-[10px] w-fit	cursor-pointer ${
                activeTab === 'arquivos' && `border-b-[2px] border-b-[#0062FF]`
              }`}
            >
              <Typography
                className={`${
                  activeTab === 'arquivos'
                    ? `text-[#0062FF] font-semibold`
                    : 'text-[#667085] font-normal'
                }`}
              >
                Arquivos
              </Typography>
            </div>
          </div>

          <div className="p-[24px] rounded bg-white mt-[24px]">
            {activeTab === 'detalhes' && (
              <>
                <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between gap-3 lg:gap-0">
                  <div className="lg:flex-[0.2]">
                    <label className="text-dark-tint mb-[4px] text-sm font-light">
                      Eixo
                    </label>
                    <p className="text-dark-base text-base font-normal">
                      {projeto?.eixo?.nome || '-'}
                    </p>
                  </div>
                  <div className="lg:flex-[0.2]">
                    <label className="text-dark-tint mb-[4px] text-sm font-light">
                      Tipo de projeto
                    </label>
                    <p className="text-dark-base text-base font-normal capitalize">
                      {projeto?.tipoProjetoEnum?.toLowerCase() || '-'}
                    </p>
                  </div>
                  <div className="lg:flex-[0.2]">
                    <label className="text-dark-tint mb-[4px] text-sm font-light">
                      Região
                    </label>
                    <p className="text-dark-base text-base font-normal">
                      {localizacao?.length > 0 ? localizacao[0].municipio : '-'}
                    </p>
                  </div>
                </div>

                <div className="flex flex-row justify-end mt-[24px]">
                  <Button
                    className="normal-case text-sm font-medium text-white bg-blue-button border-blue-button w-full lg:w-auto"
                    variant="outlined"
                    onClick={handleDialogFormLoc}
                  >
                    {localizacao?.length > 0 ? 'Editar' : 'Cadastrar'} região
                  </Button>
                </div>

                {localizacao?.length > 0 ? (
                  <div className="mt-[24px]" ref={elementRef}>
                    <MapContainer
                      center={[
                        localizacao[0].latitude,
                        localizacao[0].longitude,
                      ]}
                      zoom={13}
                      scrollWheelZoom={false}
                      whenReady={mapReady}
                    >
                      <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      />
                      <Marker
                        position={[
                          localizacao[0].latitude,
                          localizacao[0].longitude,
                        ]}
                      >
                        <Popup>{localizacao[0].endereco}</Popup>
                      </Marker>
                      {/* <LocationMarker /> */}
                    </MapContainer>
                  </div>
                ) : (
                  <div className="mt-[24px]">
                    <EmptyState text="Nenhuma região cadastrada" />
                  </div>
                )}

                <div className="my-[24px] w-full h-[1px] bg-[#E4E4E4]"></div>

                <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between gap-3 lg:gap-0">
                  <div className="flex-[0.2]">
                    <label className="text-dark-tint mb-[4px] text-sm font-light">
                      Etapa
                    </label>
                    <p className="text-dark-base text-base font-normal">
                      {projeto?.etapa?.nome || '-'}
                    </p>
                  </div>
                  <div className="flex-[0.2]">
                    <label className="text-dark-tint mb-[4px] text-sm font-light">
                      Núcleo do Governo
                    </label>
                    <p className="text-dark-base text-base font-normal">
                      {projeto?.secretaria?.nucleo?.nome}
                    </p>
                  </div>
                  <div className="flex-[0.2]">
                    <label className="text-dark-tint mb-[4px] text-sm font-light">
                      Status
                    </label>
                    <p className="text-dark-base text-base font-normal">
                      {projeto?.status?.nome || '-'}
                    </p>
                  </div>
                </div>

                <div className="my-[24px] w-full h-[1px] bg-[#E4E4E4]"></div>

                <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between gap-3 lg:gap-0">
                  <div className="flex-[0.2]">
                    <label className="text-dark-tint mb-[4px] text-sm font-light">
                      Secretaria
                    </label>
                    <p className="text-dark-base text-base font-normal">
                      {projeto?.secretaria?.nome || '-'}
                    </p>
                  </div>
                  <div className="flex-[0.2]">
                    <label className="text-dark-tint mb-[4px] text-sm font-light">
                      Responsável pelo projeto
                    </label>
                    <p className="text-dark-base text-base font-normal">
                      {projeto?.responsavel || '-'}
                    </p>
                  </div>
                  <div className="flex-[0.2]">
                    <label className="text-dark-tint mb-[4px] text-sm font-light">
                      Registro do Objeto
                    </label>
                    <p className="text-dark-base text-base font-normal">
                      {projeto?.registro?.nome || '-'}
                    </p>
                  </div>
                </div>

                <div className="my-[24px] w-full h-[1px] bg-[#E4E4E4]"></div>

                <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between gap-3 lg:gap-0">
                  <div className="flex-[0.2]">
                    <label className="text-dark-tint mb-[4px] text-sm font-light">
                      Prazo (meta)
                    </label>
                    <p className="text-dark-base text-base font-normal">
                      {!!projeto?.prazo ? formatDate(projeto.prazo) : '-'}
                    </p>
                  </div>
                  <div className="flex-[0.2]">
                    <label className="text-dark-tint mb-[4px] text-sm font-light">
                      Prazo a vencer
                    </label>
                    <p className="text-dark-base text-base font-normal">
                      {projeto?.qtdDiasPrazo || '-'}
                    </p>
                  </div>
                  <div className="flex-[0.2]">
                    <label className="text-dark-tint mb-[4px] text-sm font-light">
                      Ano
                    </label>
                    <p className="text-dark-base text-base font-normal">
                      {projeto?.ano || '-'}
                    </p>
                  </div>
                </div>

                <div className="my-[24px] w-full h-[1px] bg-[#E4E4E4]"></div>

                <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between gap-3 lg:gap-0">
                  <div>
                    <label className="text-dark-tint mb-[4px] text-sm font-light">
                      Valor global (R$)
                    </label>
                    <p className="text-dark-base text-base font-normal">
                      {!!projeto?.valorGlobal
                        ? `R$ ${setValueMasks(projeto?.valorGlobal || 0)}`
                        : '-'}
                    </p>
                  </div>
                  {/* <div>
                    <label className="text-dark-tint mb-[4px] text-sm font-light">Saldo financeiro</label>
                    <p className="text-dark-base text-base font-normal">{projeto?.saldoFinanceiro || '-'}</p>
                  </div> */}
                  <div>
                    <label className="text-dark-tint mb-[4px] text-sm font-light">
                      Valor financeiro executado
                    </label>
                    <p className="text-dark-base text-base font-normal">
                      {!!projeto?.valorFinanceiro
                        ? `R$ ${setValueMasks(projeto?.valorFinanceiro || 0)}`
                        : '-'}
                    </p>
                  </div>
                  <div>
                    <label className="text-dark-tint mb-[4px] text-sm font-light">
                      Valor financeiro a executar
                    </label>
                    <p className="text-dark-base text-base font-normal">
                      {!!projeto?.valorFinanceiroExecutar
                        ? `R$ ${setValueMasks(
                            projeto?.valorFinanceiroExecutar || 0
                          )}`
                        : '-'}
                    </p>
                  </div>
                </div>

                <div className="my-[24px] w-full h-[1px] bg-[#E4E4E4]"></div>

                <div className="flex flex-col lg:flex-row lg:items-center gap-3 lg:gap-0">
                  <div className="flex-[0.4]">
                    <label className="text-dark-tint mb-[4px] text-sm font-light">
                      Órgão
                    </label>
                    <p className="text-dark-base text-base font-normal">
                      {projeto?.orgao?.nome || '-'}
                    </p>
                  </div>
                  <div className="flex-[0.2]">
                    <label className="text-dark-tint mb-[4px] text-sm font-light">
                      Origem
                    </label>
                    <p className="text-dark-base text-base font-normal">
                      {projeto?.origem || '-'}
                    </p>
                  </div>
                </div>

                <div className="my-[24px] w-full h-[1px] bg-[#E4E4E4]"></div>

                <div className="flex flex-row items-center">
                  <div className="flex-[0.4]">
                    <label className="text-dark-tint mb-[4px] text-sm font-light">
                      Pauta
                    </label>
                    <p className="text-dark-base text-base font-normal capitalize">
                      {!!projeto?.pautaEnum
                        ? projeto.pautaEnum.toLowerCase()
                        : '-'}
                    </p>
                  </div>
                  {/* <div className="flex-[0.2]">
                    <label className="text-dark-tint mb-[4px] text-sm font-light">Classificação da demanda</label>
                    <p className="text-dark-base text-base font-normal">{projeto.cla}</p>
                  </div> */}
                </div>

                <div className="my-[24px] w-full h-[1px] bg-[#E4E4E4]"></div>

                <div className="flex flex-row items-center">
                  <div className="flex-[0.4]">
                    <label className="text-dark-tint mb-[4px] text-sm font-light">
                      Convênio
                    </label>
                    <p className="text-dark-base text-base font-normal">
                      {projeto?.convenio || '-'}
                    </p>
                  </div>
                  <div className="flex-[0.2]">
                    <label className="text-dark-tint mb-[4px] text-sm font-light">
                      Empresa
                    </label>
                    <p className="text-dark-base text-base font-normal">
                      {projeto?.empresa || '-'}
                    </p>
                  </div>
                </div>

                <div className="my-[24px] w-full h-[1px] bg-[#E4E4E4]"></div>

                <div>
                  <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between gap-3 lg:gap-0">
                    <p className="text-dark-tint font-light text-sm">
                      % da execução
                    </p>
                    <p className="text-dark-tint font-light text-sm">
                      {projeto?.percentualExecutado || 0}%
                    </p>
                  </div>
                  <Progress
                    value={projeto?.percentualExecutado || 0}
                    size="lg"
                    color="green"
                  />
                </div>
                <div className="my-[24px] w-full h-[1px] bg-[#E4E4E4]"></div>

                <div className="flex flex-row justify-end">
                  <Button
                    className="normal-case text-sm font-medium text-blue-button border-blue-button border-solid w-full lg:w-auto"
                    variant="outlined"
                    onClick={handleDialogFormProject}
                  >
                    Editar
                  </Button>
                  <Button
                    className="ml-4 normal-case text-sm font-medium bg-[#EB5757] text-white border-[#EB5757] hidden lg:block"
                    variant="outlined"
                    onClick={handleOpenDialogRemove}
                  >
                    Excluir Projeto
                  </Button>
                </div>
              </>
            )}

            {activeTab === 'ações' && (
              <>
                {!planoAcaoQuery.isLoading && planoAcaoQuery.isSuccess && (
                  <>
                    {planoAcao.length > 0 ? (
                      <div>
                        <div className="hidden lg:block">
                          <GridPlanoAcao data={planoAcao} />
                        </div>
                        <div className="block lg:hidden">
                          <GridPlanoAcaoMobile data={planoAcao} />
                        </div>
                      </div>
                    ) : (
                      <>
                        <EmptyState text="Nenhum plano de ação cadastrado" />
                      </>
                    )}
                  </>
                )}
                {planoAcaoQuery.isLoading && (
                  <div className="flex items-center justify-center">
                    <Spinner />
                  </div>
                )}
                {!planoAcaoQuery.isLoading && planoAcaoQuery.isError && (
                  <div className="w-full">
                    <BoxError text="Erro ao carregar as informações" />
                  </div>
                )}
                <div className="flex flex-row justify-end mt-[40px]">
                  <Button
                    className="normal-case text-sm font-medium text-blue-button border-blue-button border-solid"
                    variant="outlined"
                    onClick={handleDialogAcaoForm}
                  >
                    Adicionar
                  </Button>
                </div>
              </>
            )}

            {activeTab === 'situacoes' && (
              <div>
                {projetoSituacao.length > 0 ? (
                  <div className="grid grid-cols-2 gap-4">
                    {projetoSituacao.map((i) => (
                      <BoxProjetoSituacao data={i} />
                    ))}
                  </div>
                ) : (
                  <EmptyState text="Projeto não está vinculado a nenhuma situação" />
                )}
                <div className="mt-[16px] flex flex-row justify-end">
                  <Button
                    className="normal-case text-sm font-medium text-blue-button border-blue-button border-solid"
                    variant="outlined"
                    onClick={() => {
                      handleDialogAssociarSituacao();
                    }}
                  >
                    Gerenciar situações
                  </Button>
                </div>
              </div>
            )}

            {activeTab === 'arquivos' && (
              <div>
                {!allArquivos.isLoading && allArquivos.isSuccess && (
                  <div>
                    {arquivos.length > 0 ? (
                      <div className="grid grid-cols-2 gap-4">
                        {arquivos.map((i) => (
                          <CardArquivo key={i?.id} data={i} />
                        ))}
                      </div>
                    ) : (
                      <EmptyState text="Nenhum arquivo vinculado" />
                    )}
                  </div>
                )}

                {allArquivos.isLoading && (
                  <div className="flex items-center justify-center">
                    <Spinner />
                  </div>
                )}

                {!allArquivos.isLoading && allArquivos.isError && (
                  <div className="w-full">
                    <BoxError text="Erro ao carregar as informações" />
                  </div>
                )}

                <p className="mt-[24px] text-sm text-[#425466] font-light">
                  Tipo de arquivos aceitos: .pdf, .jpg, .png, .doc, .xls
                </p>
                <div className="flex flex-row justify-end mt-[40px]">
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                  />
                  <Button
                    className="normal-case text-sm font-medium text-blue-button border-blue-button border-solid"
                    variant="outlined"
                    onClick={handleButtonClick}
                  >
                    Adicionar
                  </Button>
                </div>
              </div>
            )}
          </div>
        </>
      )}

      <DialogFormPlanoAcao
        handleOpen={handleDialogAcaoForm}
        idProjeto={Number(id)}
        open={dialogAcaoForm}
      />
      {/* <DialogFormProjeto handleOpen={handleDialogFormProject} open={dialogForm} projeto={projeto} /> */}
      <DialogFormEditProjeto
        handleOpen={handleDialogFormProject}
        open={dialogForm}
        projeto={projeto}
      />
      <DialogLocalizacao
        handleOpen={handleDialogFormLoc}
        open={dialogFormLoc}
        projetoId={Number(id)}
      />
      <DialogAssociarSituacoes
        handleOpen={handleDialogAssociarSituacao}
        open={dialogAssociarSituacao}
        data={projetoSituacao}
        projetoId={Number(id)}
      />
      <DialogRemoveItem
        open={openDialogRemove}
        handleOpen={handleOpenDialogRemove}
        handleConfirm={handleRemove}
        loadingConfirm={loadingRemove}
      />
    </div>
  );
}
