import { Button, Card, CardBody, Dialog, Input, Option, Select, Spinner } from "@material-tailwind/react";
import { useEffect, useRef, useState } from "react";
import { HiX } from "react-icons/hi";
import { useAllSolucaoStatus } from "../../hooks/query/useSolucaoQuery";
import { useQueryClient } from "react-query";
import { TSolucaoInicial, createSolucao, deleteSolucao, updateSolucao } from "../../providers/solucao-inicial";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import { useAllEixo } from "../../hooks/query/useEixoQuery";
import { useAllSecretaria } from "../../hooks/query/useSecretariaQuery";
import { DialogRemoveItem } from "../DialogRemoveItem";
import { NumericFormat } from 'react-number-format'

type IProps = {
  open: boolean;
  handleOpen: () => void;
  solucao?: TSolucaoInicial;
  idSituacao: number;
}

type TFormData = Omit<TSolucaoInicial, 'id'>;

const formSchema: yup.AnyObjectSchema = yup.object().shape({
  nome: yup.string().required('Campo obrigatório'),
  descricao: yup.string().required('Campo obrigatório'),
  eixoId: yup.number().required('Campo obrigatório'),
  // responsavelId: yup.number().required('Campo obrigatório'),
  statusId: yup.number().required('Campo obrigatório'),
})

export function DialogFormSolucao({handleOpen, open, idSituacao, solucao = {} as TSolucaoInicial}: IProps) {
  const [isSave, setIsSave] = useState(false);
  const [dialogRemove, setDialogRemove] = useState(false);
  const [loadingRemove, setLoadingRemove] = useState(false);

  const solucaoStatusQuery = useAllSolucaoStatus();
  const solucaoStatus = solucaoStatusQuery.data || [];

  const eixoQuery = useAllEixo();
  const eixo = eixoQuery.data || [];

  const secretariaQuery = useAllSecretaria();
  const secretaria = secretariaQuery.data || [];

  const inputRef = useRef<any>(null);

  const queryClient = useQueryClient();

  const { ...methods } = useForm<TFormData>(
    {
      resolver: yupResolver(formSchema)
    }
  );

  useEffect(() => {
    console.log(solucao);
    if (!!solucao.nome) {
      methods.setValue('nome', solucao.nome)
    }
    if (!!solucao.descricao) {
      methods.setValue('descricao', solucao.descricao)
    }
    if (!!solucao.custoPrevisto) {
      methods.setValue('custoPrevisto', solucao.custoPrevisto)
    } else {
      methods.setValue('custoPrevisto', 0)
    }
    if (!!solucao.responsavelId) {
      methods.setValue('responsavelId', String(solucao.responsavelId) as any)
    }
    if (!!solucao.eixoId) {
      methods.setValue('eixoId', String(solucao.eixoId) as any)
    }
    if (!!solucao.statusId) {
      methods.setValue('statusId', String(solucao.statusId) as any)
    }
  }, [solucao])

  function handleDismiss() {
    methods.reset();
    setIsSave(false)
    handleOpen();
  }

  function handleDialogRemove() {
    setDialogRemove(prev => !prev);
  }

  async function handleRemove() {
    try { 
      setLoadingRemove(true);
      await deleteSolucao(solucao.id)
      await queryClient.resetQueries([idSituacao, 'find-solucao-by-situcao-id']);
      await queryClient.invalidateQueries([idSituacao, 'find-solucao-by-situcao-id']);
      toast('Solução excluída com sucesso', {type: 'success'});
      setLoadingRemove(false);
      handleDialogRemove();
    } catch(err) {
      setLoadingRemove(false);
      toast('Erro ao excluir solução', {type: 'error'});
    }
  }

  const onSubmit: SubmitHandler<TFormData> = async (params) => {
    try {
    
      setIsSave(true);


      let custoPrevisto = null;
      if (!!inputRef.current?.value) {
        custoPrevisto = inputRef.current.value.replace('R$ ', '');
        custoPrevisto = custoPrevisto.replaceAll('.', '');
        custoPrevisto = custoPrevisto.replace(',', '.');
      }

      if (!!solucao.id) {
        await updateSolucao(solucao.id, {
          ...params,
          situacaoId: idSituacao,
          responsavelId: 2,
          custoPrevisto,
        })
        toast('Solução inicial atualizado com sucesso', {type: 'success'});
      } else {
        await createSolucao({
          ...params,
          situacaoId: idSituacao,
          responsavelId: 2,
          custoPrevisto,
        });
        toast('Solução inicial criado com sucesso', {type: 'success'});
      }

      setIsSave(false)
      handleDismiss();
      
      queryClient.resetQueries([idSituacao, 'find-solucao-by-situcao-id']);
      queryClient.invalidateQueries([idSituacao, 'find-solucao-by-situcao-id']);

    } catch (err: any) {
      if (!err?.response?.data?.errors?.length) {
        toast('Erro ao criar solução', {type: 'error'});
      } else {
        toast(err.response.data.errors.join(' | '), {type: 'error'});
      }
      setIsSave(false)
    }
  }

  const onError = async () => {
  }

  return (
    <Dialog
      size="lg"
      open={open}
      handler={handleDismiss}
      className="bg-transparent shadow-none"
    >
      <Card className="p-[24px]">
        <div>
          <div className="flex flex-row items-center justify-between">
            <div>
              <h1 className="text-dark-base text-3xl font-semibold">{!!solucao.id ? 'Editar' : 'Nova'} solução ou medida</h1>
            </div>
            <div>
              <button onClick={handleDismiss}>
                <HiX size={24} color="#425466" />
              </button>
            </div>
          </div>
          <FormProvider {...methods}>
            <div className="mt-[16px]">
              <Controller
                name="nome"
                render={({field, fieldState}) => (
                  <Input label="Solução ou medida" crossOrigin={'*'} size="lg" onChange={field.onChange} value={field.value} error={!!fieldState.error}/>
                )}
              />
            </div>
            <div className="mt-[16px]">
              <Controller
                name="descricao"
                render={({field, fieldState}) => (
                  <Input label="Descrição" crossOrigin={'*'} size="lg" onChange={field.onChange} value={field.value} error={!!fieldState.error}/>
                )}
              />
            </div>
            <div className="flex flex-row items-center justify-between mt-[16px]">
              {!eixoQuery.isLoading && eixoQuery.isSuccess && ( 
                  <Controller
                    name="eixoId"
                    render={({field, fieldState}) => (
                      <Select label="Eixo" size="lg" onChange={field.onChange} value={field.value} error={!!fieldState.error} className="border-solid">
                        {eixo.map(is => (
                          <Option value={String(is.id)} key={is.id}>{is.nome}</Option>
                        ))}
                      </Select>
                    )}
                  />
              )}
              <div className="w-[16px]"></div>
              {!secretariaQuery.isLoading && secretariaQuery.isSuccess && (
                
                <Controller
                  name="responsavelId"
                  render={({field, fieldState}) => (
                    <Select label="Secretaria" size="lg" onChange={field.onChange} value={field.value} error={!!fieldState.error} className="border-solid">
                      {secretaria.map(is => (
                        <Option value={String(is.id)} key={is.id}>{is.nome}</Option>
                      ))}
                    </Select>
                  )}
                />
            )}
            </div>
            <div className="flex flex-row items-center justify-between mt-[16px]">
              <Controller
                name="custoPrevisto"
                render={({field, fieldState}) => (
                  <NumericFormat
                    thousandSeparator="."
                    decimalSeparator=","
                    prefix="R$ "
                    decimalScale={2}
                    defaultValue={field.value}
                    customInput={(props: any) => <Input label="Custo previsto" type="number" crossOrigin={'*'} size="lg" {...props} inputRef={inputRef} error={fieldState.error} />}
                  />
                )}
              />
              <div className="w-[16px]"></div>
              {!solucaoStatusQuery.isLoading && solucaoStatusQuery.isSuccess && (
                <>
                  <div className="w-[16px]"></div>
                  <Controller
                    name="statusId"
                    render={({field, fieldState}) => (
                      <Select label="Status" size="lg" onChange={field.onChange} value={field.value} error={!!fieldState.error} className="border-solid">
                        {solucaoStatus.map(is => (
                          <Option value={String(is.id)} key={is.id}>{is.nome}</Option>
                        ))}
                      </Select>
                    )}
                  />
                </>
              )}
            </div>
            <div className="mt-[24px] flex flex-row justify-end">
              <Button className="normal-case text-sm font-medium text-blue-button border-blue-button" variant="outlined" onClick={handleDismiss}>Cancelar</Button>     
              <div className="w-[8px]"></div>
              {!!solucao.id && (
                <>
                  <Button className="normal-case text-sm font-medium bg-[#EB5757] text-white border-[#EB5757]" variant="outlined" onClick={handleDialogRemove}>
                    Excluir
                  </Button>
                  <div className="w-[8px]"></div>
                </>
              )}
              <Button 
                className="normal-case text-sm font-medium text-white bg-blue-button border-blue-button" 
                variant="outlined" 
                disabled={isSave}
                onClick={methods.handleSubmit(onSubmit, onError)}
              >
                {isSave ? <Spinner color="blue" /> : `${!!solucao.id ? 'Editar' : 'Adicionar'} solução`}
              </Button>
            </div>
          </FormProvider>
        </div>
      </Card>
      <ToastContainer />
      <DialogRemoveItem handleConfirm={handleRemove} handleOpen={handleDialogRemove} open={dialogRemove} loadingConfirm={loadingRemove} />
    </Dialog>
  )
}