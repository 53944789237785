import { Button, Card, Dialog, Input, Spinner } from "@material-tailwind/react";
import { HiX } from "react-icons/hi";
import { Controller, FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from "react-query";
import { TEstrategia, createEstrategia, updateEstrategia } from "../../providers/estrategia";
import InputMask from 'react-input-mask';
import { format, parse, parseISO } from "date-fns";
import { NumericFormat } from 'react-number-format'
import { formatDateString } from "../../utils/date.utils";


type IProps = {
  open: boolean;
  handleOpen: () => void;
  estrategia?: TEstrategia;
  idSituacao: number;
}

type TFormData = {
  nome: string;
  descricao: string;
  recursoEstimado: string;
  custoPrevisto: number;
  prazoEstimado: string;
}

const formSchema: yup.AnyObjectSchema = yup.object().shape({
  nome: yup.string().required('Campo obrigatório'),
  descricao: yup.string().required('Campo obrigatório'),
  // recursoEstimado: yup.string().required('Campo obrigatório'),
  // custoPrevisto: yup.number().required('Campo obrigatório'),
  // prazoEstimado: yup.string().required('Campo obrigatório'),
})

export function DialogFormEstrategia({handleOpen, open, idSituacao, estrategia = {} as TEstrategia}: IProps) {
  const [isSave, setIsSave] = useState(false);
  const queryClient = useQueryClient();
  const inputRef = useRef<any>(null);

  const { ...methods } = useForm<TFormData>(
    {
      resolver: yupResolver(formSchema)
    }
  );

  function handleDismiss() {
    methods.reset();
    handleOpen();
  }

  useEffect(() => {
    if (!!estrategia.nome) {
      methods.setValue('nome', estrategia.nome)
    }

    if (!!estrategia.custoPrevisto) {
      methods.setValue('custoPrevisto', estrategia.custoPrevisto)
    }

    if (!!estrategia.descricao) {
      methods.setValue('descricao', estrategia.descricao)
    }

    if (!!estrategia.recursoEstimado) {
      methods.setValue('recursoEstimado', estrategia.recursoEstimado)
    }

    if (!!estrategia.prazoEstimado) {
      const dt = formatDateString(estrategia.prazoEstimado);
      methods.setValue('prazoEstimado', dt);
    }

  }, [estrategia])

  const onSubmit: SubmitHandler<TFormData> = async (params) => {
    try {
      // if (!inputRef.current?.value) {
      //   methods.setError('custoPrevisto', {});
      //   return;
      // }
      setIsSave(true);

      let custoPrevisto = null;
      if (!!inputRef.current?.value) {
        custoPrevisto = inputRef.current.value.replace('R$ ', '');
        custoPrevisto = custoPrevisto.replaceAll('.', '');
        custoPrevisto = custoPrevisto.replace(',', '.');
      }

      let prazoEstimado = '';

      if (!!params.prazoEstimado) {
        const prazoEstimadoDate = parse(params.prazoEstimado, 'dd/MM/yyyy', new Date());
        prazoEstimado = prazoEstimadoDate.toISOString();
      }


      if (!!estrategia.id) {
        await updateEstrategia(estrategia.id, {
          ...params,
          situacaoId: idSituacao,
          prazoEstimado,
          custoPrevisto
        })
        toast('Estratégia atualizada com sucesso', {type: 'success'});
      } else {
        await createEstrategia({
          ...params,
          situacaoId: idSituacao,
          prazoEstimado,
          custoPrevisto
        });
        toast('Estratégia criada com sucesso', {type: 'success'});
      }


      setIsSave(false)
      handleDismiss();
      
      
      queryClient.resetQueries([idSituacao, 'find-estrategia-by-situcao-id']);
      queryClient.invalidateQueries([idSituacao, 'find-estrategia-by-situcao-id']);

    } catch (err: any) {
      console.log(err)
      if (!err?.response?.data?.errors?.length) {
        toast('Erro ao criar estratégia', {type: 'error'});
      } else {
        toast(err.response.data.errors.join(' | '), {type: 'error'});
      }
      setIsSave(false)
    }
  }

  const onError = async () => {
    
  }

  return (
    <>
      <Dialog
        size="lg"
        open={open}
        handler={handleDismiss}
        className="bg-transparent shadow-none"
      >
        <Card className="p-[24px]">
          <div>
            <div className="flex flex-row items-center justify-between">
              <div>
                <h1 className="text-dark-base text-3xl font-semibold">{!!estrategia.id ? 'Editar estratégia de combate' : 'Nova estratégia de combate'} </h1>
              </div>
              <div>
                <button onClick={handleDismiss}>
                  <HiX size={24} color="#425466" />
                </button>
              </div>
            </div>
            <FormProvider {...methods}>
              <div className="mt-[16px]">
                <Controller
                  name="nome"
                  render={({field, fieldState}) => (
                    <Input label="Nome da estratégia" crossOrigin={'*'} size="lg" onChange={field.onChange} value={field.value} error={!!fieldState.error}/>
                  )}
                />
              </div>
              <div className="mt-[16px]">
                <Controller
                  name="descricao"
                  render={({field, fieldState}) => (
                    <Input label="Descrição" crossOrigin={'*'} size="lg" onChange={field.onChange} value={field.value} error={!!fieldState.error}/>
                  )}
                />
              </div>
              <div className="flex flex-row items-center justify-between mt-[16px]">
                
                <Controller 
                  name="recursoEstimado"
                  render={({field, fieldState}) => (
                    <Input label="Material necessário" crossOrigin={'*'} size="lg" onChange={field.onChange} value={field.value} error={!!fieldState.error}/>
                  )}
                />
                <div className="w-[16px]" />
                <Controller 
                  name="custoPrevisto"
                  render={({field, fieldState}) => (
                    <NumericFormat
                      thousandSeparator="."
                      decimalSeparator=","
                      prefix="R$ "
                      decimalScale={2}
                      defaultValue={field.value}
                      customInput={(props: any) => <Input label="Custo previsto" type="number" crossOrigin={'*'} size="lg" {...props} inputRef={inputRef} error={fieldState.error} />}
                    />
                  )}
                />
                
              </div>
              <div className="flex flex-row items-center justify-between mt-[16px]">
                <div className="flex-[0.5]">
                  <Controller 
                    name="prazoEstimado"
                    render={({field, fieldState}) => (
                      <InputMask mask="99/99/9999" value={field.value} onChange={field.onChange} >
                        {((inputProps: any) => {
                          return <Input {...inputProps} label="Prazo estimado" type="tel" error={!!fieldState.error} />
                        }) as any}
                      </InputMask>
                    )}
                  />
                </div>
              </div>
              <div className="mt-[24px] flex flex-row justify-end">      
                <Button className="normal-case text-sm font-medium text-blue-button border-blue-button" variant="outlined" onClick={handleDismiss}>Cancelar</Button>     
                <div className="w-[8px]"></div>
                <Button 
                  className="normal-case text-sm font-medium text-white bg-blue-button border-blue-button" 
                  variant="outlined" 
                  disabled={isSave}
                  onClick={methods.handleSubmit(onSubmit, onError)}>
                    {isSave ? <Spinner color="blue" /> : `${!!estrategia.id ? 'Editar' : 'Nova'} estratégia de combate`}
                  </Button>
              </div>
            </FormProvider>
          </div>
        </Card>
        <ToastContainer />
      </Dialog>
    </>
  )
}