import { useSituacao } from "../../hooks/query/useSituacaoQuery";
import { useFindLocalizacaoBySituacaoId } from "../../hooks/query/useLocalizacaoQuery";
import { ReactComponent as SpeedometerSvg } from "../../assets/icons/speedometer.svg";
import { ReactComponent as ThermometerSvg } from "../../assets/icons/thermometer.svg";
import { ReactComponent as PinangleSvg } from "../../assets/icons/pinangle.svg";
import { ReactComponent as IconDateSvg } from "../../assets/icons/icon_dt.svg";
import { useMemo, useRef, useState } from "react";
import { DialogLocalizacao } from "../SalaSituacao/DialogLocalizacao";
import { DialogFormSituacao } from "../DialogFormSituacao";
import { Button, Spinner } from "@material-tailwind/react";
import { BoxError } from "../BoxError";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { formatTipoCrise } from "../../utils/situacao.utils";
import { HiOutlinePencil, HiOutlineTrash } from "react-icons/hi";
import { deleteSituacao, findSituacao } from "../../providers/situacao";
import { findImpactoBySituacaoId } from "../../providers/impacto";
import { findSolucaoBySituacaoIdReport } from "../../providers/solucao-inicial";
import { findCenariosBySituacaoId } from "../../providers/cenario";
import { findEstrategiaBySituacaoId } from "../../providers/estrategia";
import { findPlanoAcaoBySituacaoId } from "../../providers/plano-acao";
import { toast } from "react-toastify";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from 'file-saver';
import { ReportSalaSituacao } from "../Report/ReportSalaSituacao";
import { DialogRemoveItem } from "../DialogRemoveItem";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";


type TProps = {
  id: number;
}

export function BoxResumoSalaSituacao({ id }: TProps) {
  const [openDialogRemove, setOpenDialogRemove] = useState(false);
  const [loadingRemove, setLoadingRemove] = useState(false);
  const [dialogLocalizacao, setDialogLocalizacao] = useState(false);
  const [dialogForm, setDialogForm] = useState(false);
  const situacaoQuery = useSituacao(id);
  const situacao = situacaoQuery.data
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const localizacaoQuery = useFindLocalizacaoBySituacaoId(id);
  const localizacao = localizacaoQuery.data || [];

  function handleOpenDialogRemove() {
    setOpenDialogRemove(prev => !prev)
  }

  function handleDialogLocalizacao() {
    setDialogLocalizacao(prev => !prev);
  }

  function handleDialogForm() {
    setDialogForm(prev => !prev);
  }

  const bounds = useMemo(() => {
    const positions: any[] = [];
    if (localizacao.length > 0) {
      localizacao.forEach(l => {
        positions.push([l.latitude, l.longitude])
      })
    }

    return positions
  }, [localizacao])

  const dataInicial = useMemo(() => {
    if (!!situacao?.dataInicial) {
      return formatDate(situacao.dataInicial)
    }
    return '';
  }, [situacao])

  const dataFinal = useMemo(() => {
    if (!!situacao?.dataFinal) {
      return formatDate(situacao.dataFinal)
    }
    return '';
  }, [situacao])

  async function handleRemove() {
    try { 
      setLoadingRemove(true);
      await deleteSituacao(id)
      toast('Situação excluída com sucesso', {type: 'success'});
      setLoadingRemove(false);
      handleOpenDialogRemove()

      queryClient.resetQueries([id, 'situacao']);
      queryClient.invalidateQueries([id, 'situacao']);
      navigate('/internal/sala-situacao')
    } catch(err) {
      setLoadingRemove(false);
      toast('Erro ao excluir cenário', {type: 'error'});
    }
  }

  function formatDate(date: string) {
    try {
      const splitDate = date.split('T');
      const splitDate2 = splitDate[0].split('-');
      return `${splitDate2[2]}/${splitDate2[1]}/${splitDate2[0]}`;
      return new Date(date).toLocaleDateString('pt-BR');
    } catch(err) {
      return ''
    }
  }

  const [loadingReport, setLoadingReport] = useState(false)

  async function handleReport() {
    setLoadingReport(true);

    const situacaoReport =  await findSituacao(Number(id)) as any;

    const impactoReport = await findImpactoBySituacaoId(Number(id))

    const solucaoReport = await findSolucaoBySituacaoIdReport(Number(id));

    console.log(solucaoReport);

    const cenarioReport = await findCenariosBySituacaoId(Number(id));

    const estrategiasReport = await findEstrategiaBySituacaoId(Number(id))
    
    const planosAcaoReport = await findPlanoAcaoBySituacaoId(Number(id))

    const blob = await pdf(<ReportSalaSituacao situacao={situacaoReport} impactos={impactoReport} solucoes={solucaoReport} cenarios={cenarioReport} estrategias={estrategiasReport} planos={planosAcaoReport} />).toBlob()
    saveAs(blob, 'sala-situacao.pdf')

    setLoadingReport(false)
  }

  return (
    <>
      {!situacaoQuery.isLoading && situacaoQuery.isSuccess && (
        <>
          <div className="mb-[32px]">  
            <div className="flex flex-row items-center justify-between">
              <h1 className="text-[#425466]  font-semibold leading-[42px] mr-[16px] text-[24px] lg:text-3xl">{situacao?.nome}</ h1>
              <div className="flex flex-row items-center justify-center">
                <Button className="normal-case text-sm font-medium text-white bg-blue-button border-blue-button hidden lg:block" variant="outlined" onClick={handleDialogForm}>Editar situação</Button>
                <button onClick={handleDialogForm} className="block lg:hidden">
                  <HiOutlinePencil color="#0062FF" size={24} />
                </button>
                <Button className="ml-4 normal-case text-sm font-medium bg-[#EB5757] text-white border-[#EB5757] hidden lg:block" variant="outlined" onClick={handleOpenDialogRemove}>Excluir situação</Button>
                <button onClick={handleOpenDialogRemove} className="block lg:hidden">
                  <HiOutlineTrash color="#EB5757" size={24} />
                </button>
              </div>
            </div>
            <Button className="normal-case text-sm font-medium text-white bg-blue-button border-blue-button block lg:hidden w-full mt-[12px]" variant="outlined" onClick={handleReport} disabled={loadingReport}>
              {loadingReport ? 'Carregando...' : 'Gerar relatório' }
            </Button>
          </div>
          <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between gap-[16px]">
            <div className="flex flex-row items-center gap-4">
              <div>
                <SpeedometerSvg color="#0062FF" width={40} height={40} />
              </div>
              <div>
                <label className="text-xs text-dark-tint font-normal">Tipo de crise</label>
                <h1 className="text-3xl font-semibold leading-[36px] text-dark-base capitalize">{!!situacao?.tipoCriseEnum ? formatTipoCrise(situacao.tipoCriseEnum) : '-'}</h1>
              </div>
            </div>
            <div className="flex flex-row items-center gap-4">
              <div>
                <SpeedometerSvg color="#0062FF" width={40} height={40} />
              </div>
              <div>
                <label className="text-xs text-dark-tint font-normal">Frequência da ocorrência</label>
                <h1 className="text-3xl font-semibold leading-[36px] text-dark-base capitalize">{situacao?.frequenciaOcorrenciaEnum?.toLowerCase()}</h1>
              </div>
            </div>
            <div className="flex flex-row items-center gap-4">
              <div>
                <ThermometerSvg color="#0062FF" width={40} height={40} />
              </div>
              <div>
                <label className="text-xs text-dark-tint font-normal">Gravidade da ocorrência</label>
                <h1 className="text-3xl font-semibold leading-[36px] text-dark-base capitalize">{situacao?.gravidadeOcorrenciaEnum?.toLowerCase()}</h1>
              </div>
            </div>
            {localizacaoQuery.isSuccess && localizacao.length > 0 && (
              <div className="flex flex-row items-center gap-4">
                <div>
                  <PinangleSvg color="#0062FF" width={40} height={40} />
                </div>
                <div className="flex flex-row items-center justify-center">
                  <div>
                    <label className="text-xs text-dark-tint font-normal">Região</label>
                    <h1 className="text-3xl font-semibold leading-[36px] text-dark-base capitalize">{localizacao[0].municipio}</h1>
                  </div>
                  {/* <div>
                    <button onClick={handleDialogLocalizacao}>
                      <FiPlusCircle color="#0062FF" size={24} />
                    </button>
                  </div> */}
                </div>
              </div>
            )}
          </div>
          <div className="flex flex-col lg:flex-row lg:items-center gap-[16px] mt-[16px]">
            <div className="flex flex-row items-center gap-4 w-[35%]">
              <div>
                <IconDateSvg color="#0062FF" width={40} height={40} />
              </div>
              <div>
                <label className="text-xs text-dark-tint font-normal">Data inicial</label>
                <h1 className="text-3xl font-semibold leading-[36px] text-dark-base capitalize">{dataInicial}</h1>
              </div>
            </div>
            <div className="flex flex-row items-center gap-4 w-[25%]">
              <div>
                <IconDateSvg color="#0062FF" width={40} height={40} />
              </div>
              <div>
                <label className="text-xs text-dark-tint font-normal">Data final</label>
                <h1 className="text-3xl font-semibold leading-[36px] text-dark-base capitalize">{dataFinal}</h1>
              </div>
            </div>
            <div className="w-[25%]"></div>
            <div className="w-[25%]"></div>
          </div>
          <div className="mt-[24px]">
            {bounds.length > 0 && (
              <MapContainer bounds={bounds} zoom={13} scrollWheelZoom={false}>
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {!localizacaoQuery.isLoading && localizacaoQuery.isSuccess && localizacao.length > 0 && localizacao.map(i => (
                  <Marker position={[i.latitude, i.longitude]} key={`${i.latitude}${i.longitude}`}>
                    <Popup>
                      {i.endereco}
                    </Popup>
                  </Marker>
                ))}
              </MapContainer>
            )}
          </div>
          {!localizacaoQuery.isLoading && localizacaoQuery.isSuccess && localizacao.length > 0 && localizacao.map(i => (
            <div className="mt-[24px]" key={i.id}>
              <h4 className="text-dark-tint font-light text-sm">Locais afetados</h4>
              
                <p className="text-dark-base font-normal text-base">{i.endereco}</p>
            </div>
          ))}
        </>
      )}

      {situacaoQuery.isLoading && (
        <div className="flex items-center justify-center">
          <Spinner />
        </div>
      )}

      {!situacaoQuery.isLoading && situacaoQuery.isError && (
        <div className="w-full">
          <BoxError text="Erro ao carregar as informações" />
        </div>
      )}

      <DialogLocalizacao handleOpen={handleDialogLocalizacao} open={dialogLocalizacao} idSituacao={id} />
      <DialogFormSituacao situacao={situacao} handleOpen={handleDialogForm} open={dialogForm} />
      <DialogRemoveItem open={openDialogRemove} handleOpen={handleOpenDialogRemove} handleConfirm={handleRemove} loadingConfirm={loadingRemove} />
    </> 
  )
}