import { Drawer } from "@material-tailwind/react";
import { useLocation } from "react-router-dom";
import { ReactComponent as DemandaSvg } from "../assets/icons/demanda.svg";
import { ReactComponent as DemandaActiveSvg } from "../assets/icons/demanda_active.svg";
import { ReactComponent as DistribuicaoSvg } from "../assets/icons/distribuicao.svg";
import { ReactComponent as DistribuicaoActiveSvg } from "../assets/icons/distribuicao_active.svg";
import { ReactComponent as PainelGeralSvg } from "../assets/icons/painel_geral.svg";
import { ReactComponent as PainelGeralActiveSvg } from "../assets/icons/painel_geral_active.svg";
import { ReactComponent as ProjetoSvg } from "../assets/icons/projeto.svg";
import { ReactComponent as ProjetoActiveSvg } from "../assets/icons/projeto_active.svg";
import { ReactComponent as SalaSituacaoSvg } from "../assets/icons/sala_situacao.svg";
import { ReactComponent as SalaSituacaoActiveSvg } from "../assets/icons/sala_situacao_active.svg";
import { ReactComponent as SecretariaSvg } from "../assets/icons/secretaria.svg";
import { ReactComponent as SecretariaActiveSvg } from "../assets/icons/secretaria_active.svg";
import { ReactComponent as PeopleActiveSvg } from "../assets/icons/people-outline-active.svg";
import { ReactComponent as PeopleSvg } from "../assets/icons/people-outline.svg";
import { useSidebar } from "../hooks/SidebarContext";
import { CardMenu } from "./CardMenu";

export function MenuDrawer() {
  const location = useLocation();
  const { drawerOpen, toggleDrawer } = useSidebar();

  return (
    <Drawer open={drawerOpen} onClose={() => toggleDrawer(false)} className="p-4">
      <div className="mb-6 flex items-center justify-between">
        <nav className="mt-0 py-4 px-[12px] lg:mt-0">
          <div>
            <ul className="mb-6 flex flex-col gap-2">
              <li>
                <CardMenu
                  isActive={location.pathname.includes('sala-crise')}
                  route="/internal/sala-crise"
                  text="Painel Geral"
                  icone={location.pathname.includes('sala-crise') ? <PainelGeralActiveSvg /> : <PainelGeralSvg />}
                />
              </li>
              <li>
                <CardMenu
                  isActive={location.pathname.includes('sala-situacao')}
                  route="/internal/sala-situacao"
                  text="Sala de Situação"
                  icone={location.pathname.includes('sala-situacao') ? <SalaSituacaoActiveSvg /> : <SalaSituacaoSvg />}
                />
              </li>
              <li>
                <CardMenu
                  isActive={location.pathname.includes('projeto')}
                  route="/internal/projeto"
                  text="Painel de Projetos"
                  icone={location.pathname.includes('projeto') ? <ProjetoActiveSvg /> : <ProjetoSvg />}
                />
              </li>
              <li>
                <CardMenu
                  isActive={location.pathname.includes('demandas')}
                  route="/internal/sala-situacao"
                  text="Demandas Judiciais"
                  icone={location.pathname.includes('demandas') ? <DemandaActiveSvg /> : <DemandaSvg />}
                />
              </li>
              <li>
                <CardMenu
                  isActive={location.pathname.includes('secretarias')}
                  route="/internal/secretarias"
                  text="Secretarias"
                  icone={location.pathname.includes('secretarias') ? <SecretariaActiveSvg /> : <SecretariaSvg />}
                />
              </li>
              <li>
                <CardMenu
                  isActive={location.pathname.includes('secretarias')}
                  route="/internal/pessoas"
                  text="Pessoas"
                  icone={location.pathname.includes('secretarias') ? <SecretariaActiveSvg /> : <SecretariaSvg />}
                />
              </li>
              <li>
                <CardMenu
                  isActive={location.pathname.includes('distribuicao')}
                  route="/internal/secretarias"
                  text="Distribuição por Secretaria"
                  icone={location.pathname.includes('distribuicao') ? <DistribuicaoActiveSvg /> : <DistribuicaoSvg />}
                />
              </li>
              <li>
                <CardMenu
                  isActive={location.pathname.includes('usuarios')}
                  route="/internal/usuarios"
                  text="Usuários"
                  icone={location.pathname.includes('usuarios') ? <PeopleActiveSvg /> : <PeopleSvg />}
                />
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </Drawer>
  )
}