import logo from '../../images/logo.svg';
import background from '../../images/backlogin.png';
import { Input } from "@material-tailwind/react";
import { useKeycloak } from '@react-keycloak/web';
import { useEffect } from 'react';
import InputMask from 'react-input-mask';
import { useUser } from '../../hooks/UserContext';
import { useNavigate } from 'react-router-dom';

export function SignIn() {
  const { keycloak } = useKeycloak();
  const { createToken, removeToken } = useUser()
  const navigate = useNavigate();

  useEffect(() => {
    console.log(keycloak.authenticated)
    console.log(keycloak.token)
    if (keycloak.authenticated && keycloak.token) {
      console.log('aaaaaaa')
      createToken(keycloak.token, keycloak.refreshToken)
      navigate('internal/sala-crise')
    } else {
      removeToken();
    }
  }, [
    keycloak.authenticated, keycloak.token, keycloak.refreshToken
  ])

  return (
    <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
      <div className="flex flex-wrap items-center">
        <div className="relative hidden w-full xl:block xl:w-1/2 h-screen">
          <img
            className="w-full h-full"
            src={background}
            alt="Logo"
          />
        </div>

        <div className="w-full dark:border-strokedark xl:w-1/2">
          <div className="w-full p-12 sm:p-12.5 xl:p-17.5">
            <div className="mb-[33px]">
              <img
                width={223}
                height={30}
                src={logo}
                alt="Logo"
              />
            </div>

            <div className="w-[326px] mb-[16px]">
              <h1 className="text-[#27272E] text-[24px] leading-normal font-light">A evolução da gestão pública ao seu alcance</h1>
            </div>

            <div className="w-[305px] mb-[24px]">
              <p className="text-[#425466] text-md leading-normal font-light">Faça o login com seu CPF e senha criada no seu cadastro</p>
            </div>

            <form>
              <div className="mb-[16px]">
              <InputMask mask="999-999-999.99">
                {((inputProps: any) => {
                  return <Input {...inputProps} variant="outlined" label="CPF" color="blue" size="lg" name="cpf" crossOrigin={'*'} />
                }) as any}
              </InputMask>
              </div>

              <div className="mb-6">
              <Input variant="outlined" label="Senha" color="blue" type="password" crossOrigin={'*'}/>
                
              </div>

              <div className="mb-5">
                <input
                  type="button"
                  value="Entrar"
                  onClick={() => keycloak.login()}
                  className="w-full text-sm cursor-pointer rounded-lg border border-[#0062FF] bg-[#0062FF] p-4 text-white transition hover:bg-opacity-90 font-medium"
                />
              </div>
              <div className="mb-5">
                <input
                  type="button"
                  value="Criar Conta"
                  className="w-full text-sm cursor-pointer rounded-lg border border-[#0062FF] bg-white p-4 text-[#0062FF] transition hover:bg-opacity-90 font-medium"
                />
              </div>

              <div className="mt-6 text-center cursor-pointer">
                <p className="font-sm text-[16px] text-[#0062FF]">
                  Esqueci minha senha
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}