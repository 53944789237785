import { api } from "../services/api";

export type TSituacao = {
  id: number;
  nome: string;
  tipoCriseEnum: string;
  frequenciaOcorrenciaEnum: string;
  gravidadeOcorrenciaEnum: string;
  dataInicial?: string;
  dataFinal?: string;
  regiao?: {
      id: number;
      nome: string;
  },
  linkLocalizacao?: string;
}


export async function getSituacao(): Promise<TSituacao[]> {
  try {
    const { data } = await api.get('/situacoes');
    return data.data;

  } catch(err) {
    throw err;
  }
}

export async function findSituacao(id: number): Promise<TSituacao> {
  try {
    const { data } = await api.get(`/situacoes/${id}`);
    return data.data;
  } catch(err) {
    throw err;
  }
}

export async function findSituacaoProjeto(id: number): Promise<any[]> {
  try {
    const { data } = await api.get(`/situacoes/${id}/projetos`);
    return data.data;
  } catch(err) {
    throw err;
  }
}

export async function createSituacao(params: Omit<TSituacao, 'id'>): Promise<TSituacao> {
  try {
    const {data} = await api.post('/situacoes', params);
    return data.data;
  } catch (err) {
    throw err;
  }
}

export async function updateSituacao(id: number, params: Omit<TSituacao, 'id'>): Promise<TSituacao> {
  try {
    const {data} = await api.put(`/situacoes/${id}`, params);
    return data.data;
  } catch (err) {
    throw err;
  }
}

export async function associarSituacaoProjeto(idSituacao: number, idProjeto: number) {
  try {
    let params = JSON.stringify(idProjeto);

    const {data} = await api.post(`/situacoes/${idSituacao}/projetos`, params, {
      headers: {
        'accept': '*/*',
        'Content-Type': 'application/json'
      }
    });
    return data.data;
  } catch (err) {
    throw err;
  }
}

export async function deleteSituacao(idSituacao: number) {
  try {
    const {data} = await api.delete(`/situacoes/${idSituacao}`, {
      headers: {
        'accept': '*/*',
        'Content-Type': 'application/json'
      },
    });
    return data.data;
  } catch (err) {
    throw err;
  }
}

export async function deleteSituacaoProjeto(idSituacao: number, idProjeto: number) {
  try {
    let params = JSON.stringify(idProjeto);

    const {data} = await api.delete(`/situacoes/${idSituacao}/projetos`, {
      headers: {
        'accept': '*/*',
        'Content-Type': 'application/json'
      },
      data: params
    });
    return data.data;
  } catch (err) {
    throw err;
  }
}