import { HiDownload, HiOutlineTrash } from 'react-icons/hi';
import pdf from '../../images/pdf.svg';
import doc from '../../images/doc.svg';
import eps from '../../images/eps.svg';
import ppt from '../../images/ppt.svg';
import jpg from '../../images/jpg.svg';
import jpeg from '../../images/jpeg.svg';
import excel from '../../images/excel.svg';
import png from '../../images/png.svg';
import { TBodyProjetoArquivo } from '../../providers/projeto';
import { toast } from 'react-toastify';
import { deleteArquivo, findArquivoPorId } from '../../providers/arquivo';
import { DialogRemoveItem } from '../DialogRemoveItem';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { format } from 'date-fns';

type Props = {
  data: TBodyProjetoArquivo;
};

export function CardArquivo({ data }: Props) {
  const icones = [pdf, doc, eps, ppt];
  const [dialogRemove, setDialogRemove] = useState(false);
  const [loadingRemove, setLoadingRemove] = useState(false);

  const queryClient = useQueryClient();

  function handleDialogRemove() {
    setDialogRemove(prev => !prev);
  }

  async function download() {
    if (data && data?.id) {
      try {
        const arquivo = await findArquivoPorId(data?.id);
        if (arquivo && arquivo?.url && arquivo?.tipo) {
          const link = document.createElement('a');
          const response = await fetch(arquivo?.url);
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          link.href = url;
          const tipoFile = arquivo?.tipo.split('/')[1];
          link.setAttribute("download", `download_${data?.id}.${tipoFile}`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
        toast('Download realizado com sucesso!', { type: 'success' });
      } catch (err) {
        toast('Erro ao realizar o download', { type: 'error' });
      }
    }
  }

  async function remove() {
    if (data && data?.id) {
      try {
        setLoadingRemove(true);
        await deleteArquivo(data?.id);
        setLoadingRemove(false);
        handleDialogRemove();
        toast('Arquivo deletado com sucesso!', { type: 'success' });
        queryClient.resetQueries(['all-arquivo']);
        queryClient.invalidateQueries(['all-arquivo']);
      } catch (err) {
        setLoadingRemove(false);
        toast('Erro ao deletar o arquivo', { type: 'error' });
      }
    }
  }

  function formatDate(date: string) {
    const dt = new Date(date);
    const dtDateOnly = new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000);

    try {
      return format(dtDateOnly, 'dd/MM/yyyy');
    } catch(err) {
      return ''
    }
  }

  function retornarIcone(icone: string) {
    switch (icone) {
      case 'application/pdf':
        return pdf;
      case 'image/png': 
        return png;
      case 'image/jpg': 
        return jpg;
      case 'image/jpeg': 
        return jpeg;
      case 'application/doc':
        return doc;
      case 'application/eps':
        return eps;
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
          return excel;
      case 'application/ppt':
        return ppt;
    }
  }

  return (
    <div className="p-[24px] overflow-hidden border-solid border-[1px] border-[#E4E4E4] rounded-[8px] flex items-center justify-between mr-[2px] min-h-[114px]">
      <div className="flex items-center">
        <img
          className="mr-[16px]"
          src={retornarIcone(data?.icone)}
          alt="Imagem Arquivo"
        />
        <div className="flex flex-col">
          <h1 className="text-lg text-[#1D1F2C] font-semibold mt-[4px]">
            {data?.nome.length > 20
              ? `${data?.nome?.substring(0, 20)} ...`
              : data?.nome}
          </h1>
          <div className="flex flex-col">
            <p className="text-sm text-[#667085] font-light">
              {data?.tamanho} MB
            </p>
            <p className="text-sm text-[#667085] font-light">{formatDate(data?.data)}</p>
          </div>
        </div>
      </div>
      <div className="">
        <button className="mr-[16px]" onClick={() => handleDialogRemove()}>
          <HiOutlineTrash color="#0062FF" size={20} />
        </button>
        <button>
          <HiDownload color="#0062FF" size={20} onClick={() => download()} />
        </button>
      </div>
      <DialogRemoveItem handleConfirm={remove} handleOpen={handleDialogRemove} open={dialogRemove} loadingConfirm={loadingRemove} />
    </div>
  );
}
