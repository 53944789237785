import { Link, useLocation } from "react-router-dom";
import logo from "../../images/logo.svg";
import { CardMenu } from "../CardMenu";
import { ReactComponent as PainelGeralSvg } from "../../assets/icons/painel_geral.svg";
import { ReactComponent as PainelGeralActiveSvg } from "../../assets/icons/painel_geral_active.svg";
import { ReactComponent as SalaSituacaoSvg } from "../../assets/icons/sala_situacao.svg";
import { ReactComponent as SalaSituacaoActiveSvg } from "../../assets/icons/sala_situacao_active.svg";
import { ReactComponent as SecretariaSvg } from "../../assets/icons/secretaria.svg";
import { ReactComponent as SecretariaActiveSvg } from "../../assets/icons/secretaria_active.svg";
import { ReactComponent as ProjetoSvg } from "../../assets/icons/projeto.svg";
import { ReactComponent as ProjetoActiveSvg } from "../../assets/icons/projeto_active.svg";
import { ReactComponent as DemandaSvg } from "../../assets/icons/demanda.svg";
import { ReactComponent as DemandaActiveSvg } from "../../assets/icons/demanda_active.svg";
import { ReactComponent as DistribuicaoSvg } from "../../assets/icons/distribuicao.svg";
import { ReactComponent as DistribuicaoActiveSvg } from "../../assets/icons/distribuicao_active.svg";
import { ReactComponent as PeopleActiveSvg } from "../../assets/icons/people-outline-active.svg";
import { ReactComponent as PeopleSvg } from "../../assets/icons/people-outline.svg";
import { HiChevronDoubleLeft, HiMenu } from "react-icons/hi";
import { useSidebar } from "../../hooks/SidebarContext";


const Sidebar = () => {
  const location = useLocation();
  const {sidebarOpen, toggleSidebar} = useSidebar();

  return (
    <>
      {sidebarOpen && (
        <aside
          className={`absolute left-0 top-0 z-9999 flex h-screen w-[300px] flex-col overflow-y-hidden bg-white duration-300 ease-linear dark:bg-boxdark lg:static lg:translate-x-0 ${
            sidebarOpen ? "translate-x-0" : "-translate-x-full"
          }`}
        >
          <div className="flex items-center justify-center mt-[36px] gap-2 px-6 py-5.5 lg:py-6.5">
            <Link to="/internal/sala-crise" className="">
              <img width={173} height={24} src={logo} alt="Logo" />
            </Link>
          </div>

          <div className="no-scrollbar flex flex-col overflow-y-auto duration-300 ease-linear">
            <nav className="mt-0 py-4 px-[12px] lg:mt-0">
              <div>
                <ul className="mb-6 flex flex-col gap-2">
                  <li>
                    <CardMenu
                      isActive={location.pathname.includes('sala-crise')}
                      route="/internal/sala-crise"
                      text="Painel Geral"
                      icone={location.pathname.includes('sala-crise') ? <PainelGeralActiveSvg /> : <PainelGeralSvg />}
                    />
                  </li>
                  <li>
                    <CardMenu
                      isActive={location.pathname.includes('sala-situacao')}
                      route="/internal/sala-situacao"
                      text="Sala de Situação"
                      icone={location.pathname.includes('sala-situacao') ? <SalaSituacaoActiveSvg /> : <SalaSituacaoSvg />}
                    />
                  </li>
                  <li>
                    <CardMenu
                      isActive={location.pathname.includes('projeto')}
                      route="/internal/projeto"
                      text="Painel de Projetos"
                      icone={location.pathname.includes('projeto') ? <ProjetoActiveSvg /> : <ProjetoSvg />}
                    />
                  </li>
                  <li>
                    <CardMenu
                      isActive={location.pathname.includes('demandas')}
                      route="/internal/demandas-judiciais"
                      text="Demandas Judiciais"
                      icone={location.pathname.includes('demandas') ? <DemandaActiveSvg /> : <DemandaSvg />}
                    />
                  </li>
                  <li>
                    <CardMenu
                      isActive={location.pathname.includes('secretarias')}
                      route="/internal/secretarias"
                      text="Secretarias"
                      icone={location.pathname.includes('secretarias') ? <SecretariaActiveSvg /> : <SecretariaSvg />}
                    />
                  </li>
                  <li>
                    <CardMenu
                      isActive={location.pathname.includes('pessoas')}
                      route="/internal/pessoas"
                      text="Pessoas"
                      icone={location.pathname.includes('pessoas') ? <SecretariaActiveSvg /> : <SecretariaSvg />}
                    />
                  </li>
                  <li>
                    <CardMenu
                      isActive={location.pathname.includes('distribuicao')}
                      route="/internal/distribuicao-secretaria"
                      text="Distribuição por Secretaria"
                      icone={location.pathname.includes('distribuicao') ? <DistribuicaoActiveSvg /> : <DistribuicaoSvg />}
                    />
                  <li>
                    <CardMenu
                      isActive={location.pathname.includes('usuarios')}
                      route="/internal/usuarios"
                      text="Usuários"
                      icone={location.pathname.includes('usuarios') ? <PeopleActiveSvg /> : <PeopleSvg />}
                    />
                    </li>
                  </li>
                </ul>
              </div>

              <div className="flex items-center justify-center mt-[40px]">
                <button onClick={() => toggleSidebar(false)}>
                  <HiChevronDoubleLeft size={32} />
                </button>
              </div>
              
            </nav>
            
          </div>
        </aside>
      )}
    </>
  );
};

export default Sidebar;
