import { api } from '../services/api';

export type TArquivoBody = {
  conteudo: string;
  descricao: string;
};

export type TArquivo = {
  id: number;
  tipo?: string;
  descricao?: string;
  identificador?: string;
  url?: string;
  dataCriacao?: string;
  tamanho?: number;
};

export async function findArquivo(): Promise<TArquivo[]> {
  try {
    const { data } = await api.get(`/arquivo`);
    return data.data;
  } catch (err) {
    throw err;
  }
}

export async function findArquivoPorId(id: number): Promise<TArquivo> {
  try {
    const { data } = await api.get(`/arquivo/${id}`);
    return data.data;
  } catch (err) {
    throw err;
  }
}

export async function uploadArquivo(body: TArquivoBody): Promise<TArquivo> {
  try {
    const { data } = await api.post('/arquivo', body, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return data.data;
  } catch (err) {
    throw err;
  }
}

export async function updateArquivo(
  id: number,
  body: TArquivoBody
): Promise<TArquivo> {
  try {
    const { data } = await api.put(`/arquivo/${id}`, body, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return data.data;
  } catch (err) {
    throw err;
  }
}

export async function deleteArquivo(id: number): Promise<TArquivo> {
  try {
    const { data } = await api.delete(`/arquivo/${id}`);
    return data.data;
  } catch (err) {
    throw err;
  }
}
