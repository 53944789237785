import { Button, Card, Dialog, Input, Option, Select, Spinner } from "@material-tailwind/react";
import { HiX } from "react-icons/hi";
import { useEffect, useMemo, useRef, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import { useQueryClient } from "react-query";
import { TBodyProjeto, TProjeto, createProjeto, updateProjeto } from "../../providers/projeto";
import { useAllEixo } from "../../hooks/query/useEixoQuery";
import InputMask from 'react-input-mask';
import { format, parse, parseISO } from "date-fns";
import { useAllEtapa } from "../../hooks/query/useEtapaQuery";
import { useAllSecretaria } from "../../hooks/query/useSecretariaQuery";
import { useAllProjetoOrgaos, useAllProjetoStatus } from "../../hooks/query/useProjetoQuery";
import GooglePlacesAutocomplete, { geocodeByPlaceId } from "react-google-places-autocomplete";
import { findCityInAddressComponents, findPostalCodeInAddressComponents, findStateInAddressComponents } from "../../providers/geocode";
import { TLocalizacao, createLocalizacao, deleteLocalizacao, findLocalizacaoByProjetoId } from "../../providers/localizacao";
import { MapContainer, Marker, Popup, TileLayer, useMapEvents } from "react-leaflet";
import { RiCloseCircleLine } from "react-icons/ri";
import { NumericFormat } from 'react-number-format'

type IProps = {
  open: boolean;
  handleOpen: () => void;
  projeto?: TProjeto;
}

// const formSchema: yup.AnyObjectSchema = yup.object().shape({
//   nome: yup.string().required('Campo obrigatório'),
//   unidade: yup.string().required('Campo obrigatório'),
//   especificacao: yup.string().required('Campo obrigatório'),
//   quantidade: yup.number().required('Campo obrigatório'),
//   statusId: yup.string().required('Campo obrigatório'),
// })

export function DialogFormProjeto({handleOpen, open, projeto = {} as TProjeto}: IProps) {
  const [isSave, setIsSave] = useState(false);
  const [position, setPosition] = useState<TLocalizacao>({} as TLocalizacao);

  const [positionsRemove, setPositionsRemove] = useState<TLocalizacao>({} as TLocalizacao);
  const [step, setStep] = useState(1);

  const eixoQuery = useAllEixo();
  const eixos = eixoQuery.data || [];
  
  const etapaQuery = useAllEtapa();
  const etapas = etapaQuery.data || [];

  const secretariaQuery = useAllSecretaria();
  const secretarias = secretariaQuery.data || [];

  const projetoStatusQuery = useAllProjetoStatus();
  const projetoStatus = projetoStatusQuery.data || [];

  const projetoOrgaoQuery = useAllProjetoOrgaos();
  const projetoOrgao = projetoOrgaoQuery.data || [];

  const queryClient = useQueryClient();

  const { ...methods } = useForm<TBodyProjeto>();

  const inputRef = useRef<any>(null);

  const LocationMarkerAlone = () => {
    const map = useMapEvents({
      click: handleMapClick,
    });

    if (position.latitude && position.longitude) {
      map.flyToBounds([[position.latitude, position.longitude]]);

      if (bounds.length)  {
        map.flyToBounds(bounds);
      }
    }

    return null;
  };

  const handleMapClick = (e: any) => {
    const { lat, lng } = e.latlng;
    setPosition({latitude: lat, longitude: lng, endereco: `Coordenada: ${lat}, ${lng}`, cep: '', municipio: ''});
  };

  useEffect(() => {
    if (open) {
        if (!!projeto.nome) {
          methods.setValue('nome', projeto.nome)
        }
    
        if (!!projeto?.eixo?.id) {
          methods.setValue('eixoId', String(projeto.eixo.id))
        }
    
        if (!!projeto?.tipoProjetoEnum) {
          methods.setValue('tipoProjetoEnum', projeto.tipoProjetoEnum)
        }
    
        if (!!projeto?.etapa?.id) {
          methods.setValue('etapaId', String(projeto.etapa.id))
        }
    
        if (!!projeto?.status?.id) {
          methods.setValue('statusId', String(projeto.status.id))
        }
    
        if (!!projeto?.secretaria?.id) {
          methods.setValue('secretariaId', String(projeto.secretaria.id))
        }
    
        if (!!projeto.responsavel) {
          methods.setValue('responsavel', projeto.responsavel)
        }
    
        // if (!!projeto.objeto) {
        //   methods.setValue('objeto', projeto.objeto)
        // }
    
        if (!!projeto.prazo) {
          const dt = formatDate(projeto.prazo)
          methods.setValue('prazo', dt);
        }
    
        if (!!projeto.valorGlobal) {
          methods.setValue('valorGlobal', projeto.valorGlobal)
        }
    
        if (!!projeto.origem) {
          methods.setValue('origem', projeto.origem)
        }
    
        if (!!projeto.pautaEnum) {
          methods.setValue('pautaEnum', projeto.pautaEnum)
        }
    
        if (!!projeto.orgao?.id) {
          methods.setValue('orgaoId', String(projeto.orgao.id))
        }
        
        if (!!projeto.id) {
          findLocalizacaoByProjetoId(projeto.id).then((result) => {
            if (result.length > 0) {
              setPosition(result[0])
            }
          })
        }
    }

  }, [projeto, open]);

  function handleDismiss() {
    methods.reset();
    setStep(1);
    setPosition({} as TLocalizacao);
    handleOpen();
  }

  function nextStep(st: number) {
    setStep(st);
  }

  function handleFirstStep() {
    const values = methods.getValues();

    let valid = true;

    if (!!values.nome) {
      methods.clearErrors('nome');
      valid = true
    } else {
      methods.setError('nome', {});
      valid = false;
    }

    if (!!values.eixoId) {
      methods.clearErrors('eixoId');
      valid = true;
    } else {
      methods.setError('eixoId', {});
      valid = false;
    }

    if (!!values.tipoProjetoEnum) {
      methods.clearErrors('tipoProjetoEnum');
      valid = true;
    } else {
      methods.setError('tipoProjetoEnum', {});
      valid = false;
    }

    if (valid) {
      setStep(2)
    }
  }

  function handleSecondStep() {
    const values = methods.getValues();
    
    let valid = true;

    if (!!values.etapaId) {
      methods.clearErrors('etapaId');
      valid = true;
    } else {
      methods.setError('etapaId', {});
      valid = false;
    }

    if (valid) {
      setStep(3)
    }
  }

  function handleSave() {
    const values = methods.getValues();
    
    let valid = true;

    if (valid) {
      handleFinish();
    }
  }

  function formatDate(date: string) {
    try {
      const splitDate = date.split('T');
      const splitDate2 = splitDate[0].split('-');
      return `${splitDate2[2]}/${splitDate2[1]}/${splitDate2[0]}`;
    } catch(err) {
      return ''
    }
  }

  const bounds = useMemo(() => {
    if (open && position.latitude && position.longitude) {
      return [[position.latitude, position.longitude]]
    }

    return [] as any;
  }, [position, open])
  

  async function handleFinish() {
    

    if (position.latitude && position.longitude && !position.municipio) {
      toast('Município é obrigatório', {type: 'error'});
      return;
    }

    let idProject = '';

    try {
      setIsSave(true);

      let valorGlobal = null;
      if (!!inputRef.current?.value) {
        valorGlobal = inputRef.current.value.replace('R$ ', '');
        valorGlobal = valorGlobal.replaceAll('.', '');
        valorGlobal = valorGlobal.replace(',', '.');

        // console.log(valorGlobal)

      }

      // return;

      const values = methods.getValues();
      
      let prazo = '';

      if (!!values.prazo) {
        const prazoDate = parse(values.prazo, 'dd/MM/yyyy', new Date());
        prazo = prazoDate.toISOString();
      }

      
      const response = await createProjeto({
        ...values,
        prazo: prazo,
        valorGlobal
      })

      if (!!response.id) {
        idProject = response.id
      }
      
      try {
        if (position.latitude && position.longitude) {
          await createLocalizacao({
            ...position,
            projetoId: response.id
          });
        }
      } catch (err) {

      }

      toast('Projeto criado com sucesso', {type: 'success'});
      


      setIsSave(false)
      handleDismiss();
      
      queryClient.resetQueries(['all-projeto']);
      queryClient.invalidateQueries(['all-projeto']);
      queryClient.resetQueries(['find-localizacao-by-projetos']);
      queryClient.invalidateQueries(['find-localizacao-by-projetos']);

    } catch (err: any) {

      if (!!idProject) {
        setIsSave(false)
        handleDismiss();
        
        queryClient.resetQueries(['all-projeto']);
        queryClient.invalidateQueries(['all-projeto']);
        queryClient.resetQueries(['find-localizacao-by-projetos']);
        queryClient.invalidateQueries(['find-localizacao-by-projetos']);
      }
      

      if (!err?.response?.data?.errors?.length) {
        toast('Erro ao criar projeto', {type: 'error'});
      } else {
        toast(err.response.data.errors.join(' | '), {type: 'error'});
      }
      setIsSave(false)
    }
  }

  function LocationMarker() {
    const map = useMapEvents({
      click(e) {
        // setPosition(e.latlng)
        // geocodeByLatLng({lat: e.latlng.lat, lng: e.latlng.lng}).then(results => {
        //   console.log(results)
        // })
        
      },
    })

    if (position.latitude && position.longitude) {
      map.flyToBounds([[position.latitude, position.longitude]]);
    }

    return <></>

    // map.locate()
  
    // return !position.lat || !position.lng  ? null : (
    //   <Marker position={position}>
    //     <Popup>You are here</Popup>
    //   </Marker>
    // )
  }

  function removeLocalizaca(localizacao: TLocalizacao) {
    if (!!localizacao.id) {
      setPositionsRemove(localizacao)
    }
    setPosition({} as TLocalizacao);
  }
 
  return (
    <Dialog
      size="lg"
      open={open}
      handler={handleDismiss}
      className="bg-transparent shadow-none overflow-y-scroll"
      style={{
        maxHeight: '95vh',
        overflowY: 'auto',
      }}
    >
      <Card className="p-[24px]">
        {/* <DialogBody className="h-[42rem] overflow-scroll"> */}
          <div className="flex flex-row items-center justify-between">
            <div>
              <h1 className="text-dark-base text-3xl font-semibold">{!!projeto.id ? 'Editar' : 'Novo'} projeto</h1>
            </div>
            <div>
              <button onClick={handleDismiss}>
                <HiX size={24} color="#425466" />
              </button>
            </div>
          </div>
          <div className="mt-[24px] flex flex-row items-center justify-center">
            <div className={`w-[20px] h-[10px] rounded-full ${step === 1 ? 'bg-[#0062FF]' : 'bg-[#F0F1F3]'}`}></div>
            <div className={`h-[10px] rounded-full mx-[6px] ${step === 2 ? 'bg-[#0062FF] w-[20px]' : 'bg-[#F0F1F3] w-[10px]'}`}></div>
            <div className={`h-[10px] rounded-full ${step === 3 ? 'bg-[#0062FF] w-[20px]' : 'bg-[#F0F1F3] w-[10px]'}`}></div>
          </div>
          <FormProvider {...methods}>
            {step === 1 && (
              <>
                <div className="mt-[24px]">
                  <Controller
                    name="nome"
                    render={({field, fieldState}) => (
                      <Input label="Nome do projeto" crossOrigin={'*'} size="lg" onChange={field.onChange} value={field.value} error={!!fieldState.error}/>
                    )}
                  />
                </div>
                <div className="mt-[16px] flex flex-row items-center justify-between">
                  {!eixoQuery.isLoading && eixoQuery.isSuccess && eixos.length > 0 && (
                    <Controller
                      name="eixoId"
                      render={({field, fieldState}) => (
                        <Select label="Eixo" size="lg" onChange={field.onChange} value={field.value} error={!!fieldState.error} className="border-solid">
                          {eixos.map(e => (
                            <Option value={String(e.id)}>{e.nome}</Option>
                          ))}
                        </Select>
                      )}
                    />
                  )}
                  <div className="w-[16px]"></div>
                  <Controller
                    name="tipoProjetoEnum"
                    render={({field, fieldState}) => (
                      <Select label="Tipo de projeto" size="lg" onChange={field.onChange} value={field.value} error={!!fieldState.error} className="border-solid">
                        <Option value="OBRA">Obra</Option>
                        <Option value="SERVIÇO">Serviço</Option>
                        <Option value="AQUISIÇÃO">Aquisição</Option>
                      </Select>
                    )}
                  />
                </div>
                <div className="mt-[16px]">
                  <GooglePlacesAutocomplete
                    apiKey="AIzaSyBfpn-oks9BVxtQNyAg9JvT-ZxWgu7x-7Y" 
                    apiOptions={{language: 'pt-br', region: 'pt-br'}}
                    selectProps={{
                      loadingMessage: () => 'Carregando...',
                      noOptionsMessage: () => 'Nenhuma localidade encontrada',
                      placeholder: <div>Selecione uma localidade</div>,
                      onChange(newValue, actionMeta)
                      {

                        if (actionMeta.action === 'select-option') {
                          geocodeByPlaceId(newValue?.value.place_id).then((result) => {

                            if (result.length > 0) {

                              const city = findCityInAddressComponents(result[0].address_components);
                              const state = findStateInAddressComponents(result[0].address_components)
                              const postalCode = findPostalCodeInAddressComponents(result[0].address_components);
                              const lat: number = result[0].geometry.location.lat();
                              const lng: number = result[0].geometry.location.lng();

                              

                              const localizacao: Omit<TLocalizacao, 'id'> = {
                                cep: postalCode || 72000000,
                                endereco: result[0].formatted_address,
                                latitude: lat,
                                longitude: lng,
                                municipio: city || state,
                                projetoId: 1
                              }

                              if (!localizacao.municipio) {
                                toast('A localidade selecionada não é um município', {type: 'error'});
                                return;
                              }

                              setPosition(localizacao);
                            }
                          })
                        }
                      },
                      // isMulti: true as any
                    }}
                  />
                </div>
                <div className="mt-[16px]">
                  <MapContainer bounds={bounds.length > 0 ? bounds : [[0.035574, -51.070534]]} zoom={13} scrollWheelZoom={false} >
                    <TileLayer
                      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {position.latitude && position.longitude && (
                      <Marker position={[position.latitude, position.longitude]}>
                        <Popup>
                          {position.endereco}
                        </Popup>
                      </Marker>
                    )}
                    <LocationMarker />
                    <LocationMarkerAlone />
                  </MapContainer>
                </div>
                
                {position.latitude && position.longitude && (
                  <div className="mt-[16px]">
                    <div className="w-full rounded-[4px] bg-[#F5F8FA] p-[8px] flex flex-row items-center justify-between mb-[8px] overflow-y-scroll">
                      <label className="font-medium">{position.endereco}</label>
                      <button onClick={() => removeLocalizaca(position)}>
                        <RiCloseCircleLine size={24} color="#EC407A" />
                      </button>
                    </div>
                  </div>
                )}

                <div className="mt-[24px] flex flex-row items-center justify-between">
                  <Button className="normal-case text-sm font-medium text-blue-button border-blue-button" variant="outlined" onClick={handleDismiss}>Cancelar</Button>     
                  <Button 
                    className="normal-case text-sm font-medium text-white bg-blue-button border-blue-button" 
                    variant="outlined" 
                    disabled={isSave}
                    onClick={handleFirstStep}
                    // onClick={methods.handleSubmit(onSubmit, onError)}
                  >
                    Avançar
                  </Button>
                </div>  
              </>
            )}
            {step === 2 && (
              <>
                <div className="mt-[24px] flex flex-row items-center justify-between">
                  {!etapaQuery.isLoading && etapaQuery.isSuccess && etapas.length > 0 && (
                    <Controller
                      name="etapaId"
                      render={({field, fieldState}) => (
                        <Select label="Etapa" size="lg" onChange={field.onChange} value={field.value} error={!!fieldState.error} className="border-solid">
                          {etapas.map(e => (
                            <Option value={String(e.id)}>{e.nome}</Option>
                          ))}
                        </Select>
                      )}
                    />
                  )}
                  <div className="w-[16px]"></div>
                  {!projetoStatusQuery.isLoading && projetoStatusQuery.isSuccess && projetoStatus.length > 0 && (
                    <Controller
                      name="statusId"
                      render={({field, fieldState}) => (
                        <Select label="Status" size="lg" onChange={field.onChange} value={field.value} error={!!fieldState.error} className="border-solid">
                          {projetoStatus.map(e => (
                            <Option value={String(e.id)}>{e.nome}</Option>
                          ))}
                        </Select>
                      )}
                    />
                  )}
                </div>
                <div className="mt-[16px]">
                  {!secretariaQuery.isLoading && secretariaQuery.isSuccess && secretarias.length > 0 && (
                    <Controller
                      name="secretariaId"
                      render={({field, fieldState}) => (
                        <Select label="Secretaria" size="lg" onChange={field.onChange} value={field.value} error={!!fieldState.error} className="border-solid">
                          {secretarias.map(e => (
                            <Option value={String(e.id)}>{e.nome}</Option>
                          ))}
                        </Select>
                      )}
                    />
                  )}
                </div>
                <div className="mt-[16px] flex flex-row items-center justify-between">
                  <Controller
                    name="responsavel"
                    render={({field, fieldState}) => (
                      <Input label="Responsável" crossOrigin={'*'} size="lg" onChange={field.onChange} value={field.value} error={!!fieldState.error}/>
                    )}
                  />
                  {/* <div className="w-[16px]"></div>
                  <Controller
                    name="objeto"
                    render={({field, fieldState}) => (
                      <Input label="Objeto" crossOrigin={'*'} size="lg" onChange={field.onChange} value={field.value} error={!!fieldState.error}/>
                    )}
                  /> */}
                </div>
                <div className="mt-[24px] flex flex-row items-center justify-between">
                  <Button className="normal-case text-sm font-medium text-blue-button border-blue-button" variant="outlined" onClick={handleDismiss}>Cancelar</Button>     
                  <div className="flex flex-row items-center"> 
                    <Button className="normal-case text-sm font-medium text-blue-button border-blue-button" variant="outlined" onClick={() => nextStep(1)}>Voltar</Button>     
                    <div className="w-[16px]" />
                    <Button 
                      className="normal-case text-sm font-medium text-white bg-blue-button border-blue-button" 
                      variant="outlined" 
                      disabled={isSave}
                      onClick={handleSecondStep}
                      // onClick={methods.handleSubmit(onSubmit, onError)}
                    >
                      Avançar
                    </Button>
                  </div>
                </div>  
              </>
            )}
            {step === 3 && (
              <>
                <div className="flex flex-row items-center justify-between mt-[24px]">
                  <Controller
                    name="prazo"
                    render={({field, fieldState}) => (
                      <InputMask mask="99/99/9999" value={field.value} onChange={field.onChange} >
                        {((inputProps: any) => {
                          return <Input {...inputProps} label="Prazo" type="tel" error={!!fieldState.error} />
                        }) as any}
                      </InputMask>
                    )}
                  />
                  <div className="w-[16px]"></div>
                  <Controller
                    name="valorGlobal"
                    render={({field, fieldState}) => (
                      // <Input label="Valor global" type="number" crossOrigin={'*'} size="lg" onChange={field.onChange} value={field.value} error={!!fieldState.error}/>
                      <NumericFormat
                        thousandSeparator="."
                        decimalSeparator=","
                        prefix="R$ "
                        decimalScale={2}
                        defaultValue={field.value}
                        customInput={(props: any) => <Input label="Valor global" type="number" crossOrigin={'*'} size="lg" {...props} inputRef={inputRef} error={fieldState.error} />}
                      />
                    )}
                  />
                </div>
                <div className="flex flex-row items-center justify-between mt-[16px]">
                  {!projetoOrgaoQuery.isLoading && projetoOrgaoQuery.isSuccess && projetoOrgao.length > 0 && (
                    <Controller
                      name="orgaoId"
                      render={({field, fieldState}) => (
                        <Select label="Orgão" size="lg" onChange={field.onChange} value={field.value} error={!!fieldState.error} className="border-solid">
                          {projetoOrgao.map(e => (
                            <Option value={String(e.id)}>{e.nome}</Option>
                          ))}
                        </Select>
                      )}
                    />
                  )}
                  <div className="w-[16px]"></div>
                  <Controller
                    name="origem"
                    render={({field, fieldState}) => (
                      <Input label="Origem" crossOrigin={'*'} size="lg" onChange={field.onChange} value={field.value} error={!!fieldState.error}/>
                    )}
                  />
                </div>
                <div className="flex flex-row items-center justify-between mt-[16px]">
                  <Controller
                    name="pautaEnum"
                    render={({field, fieldState}) => (
                      <Select label="Pauta" size="lg" onChange={field.onChange} value={field.value} error={!!fieldState.error} className="border-solid">
                        <Option value="CRITICA">Crítica</Option>
                        <Option value="POSITIVA">Positiva</Option>
                      </Select>
                    )}
                  />
                </div>
                <div className="mt-[24px] flex flex-row items-center justify-between">
                  <Button className="normal-case text-sm font-medium text-blue-button border-blue-button" variant="outlined" onClick={handleDismiss}>Cancelar</Button>     
                  <div className="flex flex-row items-center"> 
                    <Button className="normal-case text-sm font-medium text-blue-button border-blue-button" variant="outlined" onClick={() => nextStep(2)}>Voltar</Button>     
                    <div className="w-[16px]" />
                    <Button 
                      className="normal-case text-sm font-medium text-white bg-blue-button border-blue-button" 
                      variant="outlined" 
                      disabled={isSave}
                      onClick={handleSave}
                      // onClick={methods.handleSubmit(onSubmit, onError)}
                    >
                      {isSave ? <Spinner color="blue" /> : `${!!projeto.id ? 'Editar' : 'Adicionar'} projeto`}
                    </Button>
                  </div>
                </div>  
              </>
            )}
          </FormProvider>
      </Card>
      <ToastContainer />
    </Dialog>
  )
}